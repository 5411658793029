import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../layouts"
import '../styles/shop.scss';
import { Helmet } from "react-helmet"
import 'uikit/dist/css/uikit.css'



// const CTAList = loadable(() => import("../components/cta-list/ctaList"))

import CTAList from '../components/cta-list/ctaList';

const Shop = (props) => {

  const data = useStaticQuery(graphql`
        {  
          allFile {
            edges {
              node {
                publicURL
                id
                name
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          allStrapiShops(sort: {fields: Title, order: ASC}) {
            edges {
              node {
                TextContent
                Title
                Slug
                shop_category {
                  Slug
                }
                MainImage {
                    localFile{
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 1900) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                            gatsbyImageData(
                              width: 1900
                              placeholder: BLURRED
                              formats: [AUTO, WEBP]
                            )
                          }
                    }
                }
                Alt
              }
            }
          }
          strapiShopPage {
            FooterDisclaimer
            SEODescription
            SEOTitle
            Slug
            }
            allStrapiShopCategories {
             edges {
              node {
                Slug
                Title
              }
            }
          }

        }


    `)

  const contentsList = [];

  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

        // imgUrl = item.node.publicURL
      }
      return ("")

    })
    // console.log(imgUrl)
    return (imgUrl)
  }

  var searchURL = "";

  if (typeof window !== "undefined" && props.location?.search != undefined && props.location.search.split('=').length >= 1) {
    searchURL = props.location.search.split('=')[1];
  }


  return (

    <Layout SEOTitle={data.strapiShopPage.SEOTitle} SEODescription={data.strapiShopPage.SEODescription} footerDisclaimer={data.strapiShopPage.footerDisclaimer}>

      {/** List of meta properties coming from strapi that applies only for the homepage */
        <Helmet>
          {data.strapiShopPage.MetaTags?.map((item, index) => {
            return (
              <>
                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
              </>
            )
          })}
        </Helmet>
      }
      <div className="shop-container">
        <div className="shop-content">
          {
            searchURL ?
              data.allStrapiShops.edges ? data.allStrapiShops.edges.map((item, index) => {

                if (item.node.shop_category) {

                  if (item.node.shop_category.Slug === searchURL) {
                    contentsList.push({

                      Title: item.node.Title,
                      Image: item.node.MainImage,
                      Alt: item.node.Alt,
                      Slug: item.node.Slug,
                      Description: item.node.TextContent,
                      CardType: 'shop'

                    })
                  }

                }

              }) : "" : data.allStrapiShops.edges ? data.allStrapiShops.edges.map((item, index) => {


                contentsList.push({

                  Title: item.node.Title,
                  Image: item.node.MainImage,
                  Alt: item.node.Alt,
                  Slug: item.node.Slug,
                  Description: item.node.TextContent,
                  CardType: 'shop'

                })
              }) : ""
          }
         
          <div className="shop-page-header">
            <h3>Shops</h3>
            <div className="shop-categories">
              <ul className="categories-list">
                <li className="category"><Link to="/shop" className="category-link">All shops</Link></li>
                {
                  data.allStrapiShopCategories.edges ? data.allStrapiShopCategories.edges.map((item, index) => {

                    return (

                      <li key={index} className={`category`}>
                        <Link to={`/shop/?type=` + item.node.Slug} className={`category-link ${searchURL === item.node.Slug ? "category-link-selected" : ""}`}>
                          {item.node.Title}
                        </Link>
                      </li>

                    )

                  }) : ""
                }

              </ul>
            </div>
          </div>

          {
            contentsList ?
              <div className="shop-cards">
                <CTAList
                  contents={contentsList}
                  carType={'shop'}
                  searchUrl={searchURL}
                ></CTAList>
              </div>
              : ""

          }
          <ul>

          </ul>
        </div>
      </div>


    </Layout>

  )

}

export default Shop